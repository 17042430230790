import React, { useMemo } from 'react';

import Layout from 'src/components/layout/layout';
import Member from 'src/components/member/member';
import SEO from 'src/components/seo';
import { usePrivateRoute } from 'src/utils/auth';

const MemberPage = ({ location }) => {
  usePrivateRoute();
  return (
    <Layout>
      <SEO title="Member" />
      <Member member={location.state?.t} />
    </Layout>
  );
};
export default MemberPage;
